<template>
  <div class="home">
    <div class="tr1">
      <div class="le border">
        <dv-border-box-1 ><div class="tit">{{name}}</div></dv-border-box-1>
      </div>
      <div class="ri border">{{ date | dateshi }}</div>
    </div>
    <div class="tr2">
      <div class="changxian">
        <changxian />
      </div>
      <div class="quyu">
        <quyu />
      </div>
      <div class="xunchadian">  
        <xunjiandian />
      </div>
    </div>
    <div class="tr3">
      <div class="le">
        <swipe-table />
      </div>
      <div class="ri">
        <yueduxunjian />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import changxian from "@/components/changxian";
import quyu from "@/components/quyu";
import xunjiandian from "@/components/xunjiandian";
import yueduxunjian from "@/components/yueduxunjian";
import swipeTable from "@/components/swipeTable";
import { dateshi } from "@/utils";
import { CompaniesList } from "@/request/api";
export default {
  data() {
    return {
      tiem: null,
      date: "",
      name:'温州市金田塑业有限公司巡检数据大屏'
    };
  },
  created(){
    this.$store.commit('setId',this.$route.query.CompanyId)
  },
  mounted() {
    this.CompaniesList()
    this.date = new Date();
    if (!this.time) {
      this.time = setInterval(() => {
        this.date = new Date();
      }, 1000);
    } else {
      clearInterval(this.time);
      this.time = setInterval(() => {
        this.date = new Date();
      }, 1000);
    }
  },
  methods: {
    CompaniesList(){
      CompaniesList().then(res=>{
        res.data.forEach(item => {
          if(item.Id==this.$store.state.id){
            this.name = item.Name + '巡检数据大屏'
          }
        });
      })
    }
  },
  components: {
    changxian,
    quyu,
    xunjiandian,
    swipeTable,
    yueduxunjian,
  },
  filters: {
    dateshi,
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  background: url("../assets/background2.png");
  background-size: 100%, 100%;
  color: #fff;
}
.tr1 {
  width: 100%;
  height: 10.44%;
  position: relative;
  background: rgba(0, 0, 255, .05);
  box-shadow:0px 0px 75px 0px rgb(5, 5, 114);
  .le {
    width: 100%;
    height: 100%;
    .tit {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.5rem;
      white-space: nowrap;
    }
  }
  .ri {
    position: absolute;
    right: 0;
    top: 0;
    width: 20%;
    height: 100%;
    font-size: 0.24rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.tr2 {
  width: 100%;
  height: 45%;
  display: flex;
  .xunchadian,
  .quyu,
  .changxian {
    height: 100%;
    flex: 1;
  }
}
.tr3 {
  width: 100%;
  height: 45%;
  display: flex;
  justify-content: space-around;
  .le,
  .ri {
    width: 37%;
    height: 100%;
  }
  .le{
    width: 60%;
  }
}
</style>