<template>
  <div id="xunjiandian">
    <dv-border-box-7>
      <div class="chart border" ref="xunjiandian"></div
    ></dv-border-box-7>
  </div>
</template>
<script>
import { DataChart2 } from "@/request/api";
export default {
  data() {
    return {
      myChart: null,
      time: null,
    };
  },
  mounted() {
     if(!this.time){
      this.time = setInterval(() => {
      this.DataChart2();
    }, 1000*60);
    }else{
      clearInterval(this.time) 
       this.time = setInterval(() => {
      this.DataChart2();
    }, 1000*60);
    }
    this.DataChart2();
    this.chart();
  },
  destroyed() {
    window.removeEventListener("resize", this.screenAdapter);
  },
  methods: {
    async DataChart2() {
      let res = await DataChart2({ TypeId: 28, CompanyId: this.$store.state.id });
      let value = []
      let name = []
      res.data.dynamicCirque.data.forEach(item => {
        value.push(item.value)
        name.push(item.name)
      });
      let data = {
        series: [
          {
            data: value,
          },
        ],
        xAxis: [
          {
            data: name,
          },
        ],
      };
      this.myChart.setOption(data);
    },
    chart() {
      var chartDom = this.$refs.xunjiandian;
      this.myChart = this.$echarts.init(chartDom,this.$store.state.theme);
      var option;

      option = {
         title:{
          text:'巡检点异常次数top10',
          padding: [20,0,0,40],
        },
        tooltip: {
          trigger: "item",
        },
        backgroundColor: 'transparent',
        grid:{
            top:95,
            right:35,
            left:50,
            bottom:50
        },
        legend: {
          top:47,
          data: ["巡检点异常次数top10"],
          icon: "circle",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            interval: 0,
            rotate: -15, //倾斜的程度
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["rgb(225,225,225)"],
            },
          },
          
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgb(225,225,225)",
            },
          },
        },

        series: [
          {
            type: "bar",
            name: "巡检点异常次数top10",
            color: "rgb(138,207,87)",
          },
        ],
        label: {
          show: true,
        },
      };
      option && this.myChart.setOption(option);
      window.addEventListener("resize",this.screenAdapter);
    },
    screenAdapter() {
      this.myChart.resize();
    },
  },
};
</script>
<style lang="less">
#xunjiandian {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    height: 15%;
    font-size: 0.24rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .chart {
    height: 90%;
    // background: chartreuse;
  }
}
</style>
