import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts';
import dataV from '@jiaminghi/data-view'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(dataV)

// lodash引入
import _ from 'lodash'
Vue.prototype._ = _

let vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

let size = document.documentElement.clientWidth / 1920
Vue.prototype.$size = size
document.documentElement.style.fontSize = document.documentElement.clientWidth / 19.2 + 'px'; //设计稿是750px ，HTML的font-size:100px;
window.onresize = function () {
  size = document.documentElement.clientWidth / 1920
  Vue.prototype.$size = size
  let currentWidth = document.documentElement.clientWidth / 19.2;
  document.documentElement.style.fontSize = currentWidth + "px";

};



setTimeout(()=>{
  window.location.href = `http://db1.gettel.cc/?CompanyId=${vm.$route.query.CompanyId}`
},1000  * 60 * 5)


