<template>
  <div id="swipeTable">
      <div class="title">今日巡检异常信息</div>
    <dv-scroll-board ref="scrollBoard" :config="config"  style="width: 100%; height: 75%" />
  </div>
</template>
<script>
import { DataChart1 } from "@/request/api";
export default {
  data() {
    return {
      config: {},
       time: null,
    };
  },
  mounted(){
    if(!this.time){
      this.time = setInterval(() => {
      this.DataChart2();
    }, 1000 * 60 * 10);
    }else{
      clearInterval(this.time) 
       this.time = setInterval(() => {
      this.DataChart2();
    },1000 * 60 * 10);
    }
    this.DataChart2()
  },
  methods:{
    async DataChart2() {
      let res = await DataChart1({ TypeId: 34, CompanyId: this.$store.state.id });
      
    this.config = {
       rowNum:3,
       headerHeight:70,
       waitTime:4000,
       hoverPause:true,
       align:['center','center','center','center','center','center','center'],
       columnWidth:[100,300,300,330,120,300],
       ...res.data.theRotaTables
     }
    },
  }
};
</script>
<style lang="less" scoped>
#swipeTable {
  width: 100%;
  height: 100%;
  .title{
      font-size: .3rem;
      width: 100%;
      height: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
  }
}
</style>