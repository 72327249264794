<template>
  <div id="changxian">
    <dv-border-box-4>
      <div class="chart border" ref="changxian"></div>
    </dv-border-box-4>
  </div>
</template>
<script>
import { DataChart2 } from "@/request/api";
export default {
  data() {
    return {
      myChart: null,
      time: null,
      h: 0,
    };
  },
  mounted() {
    this.h = this.$refs.changxian.getBoundingClientRect().height / 486;
    if (!this.time) {
      this.time = setInterval(() => {
        this.DataChart2();
      }, 1000 * 60);
    } else {
      clearInterval(this.time);
      this.time = setInterval(() => {
        this.DataChart2();
      }, 1000 * 60);
    }
    this.DataChart2();
    this.chart();
  },
  destroyed() {
    window.removeEventListener("resize", this.screenAdapter);
  },
  methods: {
    async DataChart2() {
      let res = await DataChart2({
        TypeId: 27,
        CompanyId: this.$store.state.id,
      });
      let dataList=this._.chunk(res.data.data,10)[0]
      let data = {
        series: [
          {
            data: dataList,
          },
        ],
        legend: {
          formatter: function (name) {
            // 获取legend显示内容
            let data = dataList;
            let tarValue;
            for (let i = 0, l = data.length; i < l; i++) {
              if (data[i].name == name) {
                tarValue = data[i].value;
              }
            }
            return name + " " + " " + tarValue;
          },
        },
      };
      this.myChart.setOption(data);
    },
    chart() {
      let then = this;
      var chartDom = this.$refs.changxian;
      this.myChart = this.$echarts.init(chartDom, this.$store.state.theme);
      var option;

      option = {
        title: {
          text: "产线异常数据对比",
          padding: [50, 0, 0, 40],
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          show:false,
          // type: "scroll",
          // orient: "vertical",
          // left: 36,
          // top: 80,
          // height: 200,
        },
        backgroundColor: "transparent",
        series: [
          {
            name: "产线异常数据对比",
            type: "pie",
            radius: "70%",
            top:"10%",
            bottom:"10%",
            // left: "40%",
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
               show:true,
              // position: "center",
            },
            labelLine:{
                show:true
            },
          },
        ],
      };
      option && this.myChart.setOption(option);
      window.addEventListener("resize", () => {
        //  this.h = this.$refs.quyu.getBoundingClientRect().height / 486
        // option && then.myChart.setOption({legend:{height:then.$refs.changxian.getBoundingClientRect().height / 486*320}});
        then.myChart.resize();
      });
    },
    screenAdapter() {
      //  this.h = this.$refs.changxian.getBoundingClientRect().height / 486
      // this.myChart.resize();
    },
  },
};
</script>
<style lang="less">
#changxian {
  width: 100%;
  height: 100%;
  .title {
    height: 15%;
    font-size: 0.24rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .chart {
    height: 100%;
  }
}
</style>
